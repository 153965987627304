import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PathInterceptorService } from '@core/interceptors/path-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeaturesModule } from '@views/features/features.module';
import { TokenInterceptorService } from '@core/interceptors/token.interceptor';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LogInGuard } from '@core/guards/log-in-guard.guard';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FeaturesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PathInterceptorService,
      multi: true,
    },
    provideClientHydration(), 
    provideNzI18n(en_US), 
    importProvidersFrom(FormsModule), 
    importProvidersFrom(HttpClientModule), 
    provideAnimations(),
    LogInGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
